
<template>
  <div class="app_con">
    <div class="app-container">
      <el-table
        class="m-b-20"
        ref="table"
        :data="listData"
        border
        highlight-current-row
        row-key="id"
      >
        <template v-for="v in props">
          <el-table-column
            v-if="v.type == 'operationLink'"
            :key="v.id"
            :align="v.align"
            :label="v.label"
            :width="v.width"
          >
            <template slot-scope="scope">
              <span>
                <template v-for="(item, index) in v.formatter(scope.row)">
                  <el-link
                    :style="{
                      marginRight:
                        v.formatter(scope.row).length > 1 && index == 0
                          ? '20px'
                          : '',
                    }"
                    :icon="item.icon"
                    :key="index"
                    :type="item.type"
                    v-if="item.show"
                    @click="openDialogVisible(scope.row)"
                    >{{ item.text }}</el-link
                  >
                </template>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            v-else
            :key="v.id"
            :align="v.align"
            :prop="v.prop"
            :label="v.label"
            :width="v.width"
          >
          </el-table-column>
        </template>
      </el-table>
      <el-pagination
        background
        v-bind="pageInfo"
        layout="sizes, prev, pager, next, jumper"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>
    <el-dialog
      title="对账"
      width="800px"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <el-form label-width="80px" class="inlineForm">
        <el-form-item label="对账结果">
          <el-input
            v-model="resultData.result"
            clearable
            placeholder="请输入对账结果"
          >
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex justify-center align-center">
            <el-button
              @click="
                dialogVisible = false;
                resultData.result = '';
              "
              >取消</el-button
            >
            <el-button type="primary" :loading="loading" @click="handelAdd()"
              >提交</el-button
            >
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    const self = this;
    return {
      remote: api.common,
      apiName: "/vip/marketRatioBill",
      searchQuery: { refurbishNum: 0 },
      listData: [],
      pageInfo: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      dialogVisible: false,
      loading: false,
      props: [
        {
          label: "对账日期",
          prop: "ratioBillDate",
          align: "center",
        },
        {
          label: "会员起始金额",
          prop: "vipStartMoney",
          align: "center",
        },
        {
          label: "会员充值金额",
          prop: "vipRechargeMoney",
          align: "center",
        },
        {
          label: "会员退卡金额",
          prop: "vipReturnMoney",
          align: "center",
        },
        {
          label: "会员截止金额",
          prop: "vipEndMoney",
          align: "center",
        },

        {
          label: "交易金额",
          prop: "transactionMoney",
          align: "center",
        },
        {
          label: "结果",
          prop: "result",
          align: "center",
        },
        {
          label: "操作",
          prop: "detail",
          align: "center",
          type: "operationLink",
          width: 160,
          formatter: function (row) {
            const buttonList = [
              {
                text: "对账",
                size: "mini",
                data: row,
                show: true,
                icon: "el-icon-edit",
                type: "primary",
                handler: function () {
                  self.ratioBill(row);
                },
              },
            ];
            return buttonList;
          },
        },
      ],
      resultData: {
        id: 0,
        result: "",
      },
    };
  },
  watch: {
    searchQuery: {
      handler() {
        this.getItems();
      },
      deep: true,
    },
  },
  computed: {},
  created() {
    this.getItems();
  },
  methods: {
    async getItems() {
      this.pageInfo.apiName = this.apiName;
      let result = await this.remote.list(this.pageInfo);
      this.listData = result.data.data;
      this.pageInfo.total = parseInt(result.data.total);
    },
    ratioBill(row) {
      console.log(row);
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getItems();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getItems();
    },
    openDialogVisible(e) {
      console.log(e);
      this.resultData.id = e.id;
      this.dialogVisible = true;
    },
    async handelAdd() {
      const { resultData } = this;
      if (resultData.result == "") {
        this.$message({ message: "请输入对账结果", type: "error" });
        return;
      }
      this.loading = true;
      let result = await api.common.post({
        apiName: "/vip/marketRatioBill/ratioBill",
        ...resultData,
      });
      this.$message({ message: result.message, type: "success" });
      this.loading = false;
      this.dialogVisible = false;
      this.searchQuery.refurbishNum++;
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
</style>
